<template>
  <v-card flat class="px-2">
    <v-row>
      <v-col cols="12">
        <v-tabs show-arrows height="50" v-model="tab" id="tab-data">
          <v-tab
            class="mr-2 px-5 text-upppercase"
            v-for="(data, i) in Object.keys(keluarga)"
            :key="i"
          >
            <span>{{ data | snakeToTitle }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(data, i) in Object.keys(keluarga)" :key="i">
            <v-simple-table id="table-custom">
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>No</th>
                    <th>Jenis Kelamin</th>
                    <th>Nama</th>
                    <th>Tempat/Tanggal Lahir</th>
                    <th>Tanggal Menikah</th>
                    <th>Pekerjaan</th>
                    <th>Keterangan</th>
                    <th>Aksi</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-if="keluarga[data] && keluarga[data].length == 0">
                    <td colspan="8">
                      <ContentNotFound :message="`Data ${data} tidak ada.`" />
                    </td>
                  </tr>
                  <tr v-for="(item, index) in keluarga[data]" :key="index">
                    <td>{{ index + 1 }}</td>
                    <td>{{ item.hubungan }}</td>
                    <td>{{ item.nama }}</td>
                    <td>
                      {{ item.tempat_lahir && `${item.tempat_lahir},` }}
                      {{ item.tgl_lahir | date }}
                    </td>
                    <td>{{ item.tgl_nikah | date }}</td>
                    <td>{{ item.pekerjaan }}</td>
                    <td>{{ item.keterangan }}</td>
                    <td>
                      <v-btn
                        @click="edit(item, data)"
                        icon
                        small
                        color="primary"
                        ><v-icon small>edit</v-icon></v-btn
                      >
                      <!-- <v-btn
                        @click="remove(item, data)"
                        icon
                        small
                        color="error"
                        ><v-icon small>delete</v-icon></v-btn
                      > -->
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-actions>
              <v-btn color="primary" small @click="add(data)">
                Tambah
              </v-btn>
            </v-card-actions>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
    <DataKeluargaDialog ref="dataKeluarga" @on-refresh="$emit('on-refresh')" />
  </v-card>
</template>
<script>
import { mapEmployeeRootField } from "@/store/helpers";
const ContentNotFound = () => import("@/components/Content/NotFound");
const DataKeluargaDialog = () => import("@/components/Dialog/DataKeluarga");
import PegawaiService from "@/services/resources/pegawai.service";
import KeluargaService from "@/services/resources/keluarga.service";

export default {
  components: {
    ContentNotFound,
    DataKeluargaDialog
  },
  data() {
    return {
      id: this.$route.params ? this.$route.params.employeeId : null,
      tab: 0,
      loading: false,
      formLoading: false
    };
  },
  computed: {
    ...mapEmployeeRootField({
      personal: "personal",
      keluarga: "keluarga"
    })
  },
  methods: {
    add(type) {
      let kelamin = "";
      if (type == "pasangan") {
        kelamin = this.personal.kelamin == "Pria" ? "Wanita" : "Pria";
      }
      let item = {
        NIP: this.id,
        hubungan: type,
        kelamin
      };
      this.$refs.dataKeluarga.add(item, this.keluarga[type].length);
    },
    edit(item, type) {
      this.$refs.dataKeluarga.edit(
        {
          NIP: this.id,
          ...item
        },
        this.keluarga[type].length
      );
    },
    remove(item) {
      this.$confirm({
        title: "Confirm",
        message: `Apakah anda yakin akan menghapus data ini?`,
        button: {
          no: "Batal",
          yes: "Ya"
        },
        callback: confirm => {
          if (confirm) {
            let payload = {
              NIP: this.id,
              hubungan: item.hubungan
            };
            this.deleteFamily(payload);
          }
        }
      });
    },
    // Service
    async listFamily() {
      try {
        this.loading = true;
        await KeluargaService.getList({
          NIP: this.personal.NIP
        })
          .then(response => {
            const { status, data } = response.data;
            if (status) {
              let pasangan = [];
              let anak = [];
              let orang_tua = [];
              let mertua = [];
              let saudara = [];
              data.forEach(family => {
                if (
                  family.hubungan.includes("Suami") ||
                  family.hubungan.includes("Istri")
                ) {
                  pasangan.push(family);
                } else if (family.hubungan.includes("Anak")) {
                  anak.push(family);
                } else if (
                  family.hubungan.includes("BapakKandung") ||
                  family.hubungan.includes("IbuKandung")
                ) {
                  orang_tua.push(family);
                } else if (
                  family.hubungan.includes("BapakMertua") ||
                  family.hubungan.includes("IbuMertua")
                ) {
                  mertua.push(family);
                } else if (family.hubungan.includes("SaudaraKandung")) {
                  saudara.push(family);
                }
              });
              this.keluarga = {
                pasangan,
                anak,
                orang_tua,
                mertua,
                saudara
              };
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.loading = false));
      } catch (err) {
        console.error(err);
      }
    },
    async deleteFamily(payload) {
      try {
        this.formLoading = true;
        await PegawaiService.deleteFamily(payload)
          .then(response => {
            const { status, message } = response.data;
            if (status) {
              this.$emit("on-refresh");
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "success"
              });
            } else {
              this.$store.commit("snackbar/setSnack", {
                show: true,
                message: message,
                color: "error"
              });
            }
          })
          .catch(error => {
            throw new Error(error);
          })
          .finally(() => (this.formLoading = false));
      } catch (err) {
        console.error(err);
      }
    }
  },
  mounted() {
    this.listFamily();
  }
};
</script>
<style lang="scss">
#table-custom.v-data-table {
  color: #717171 !important;
  .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 0.675rem;
  }
}
#table-custom .v-input {
  font-size: 0.675rem;
}
</style>
