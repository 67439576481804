import BaseInstance from "../base.instance";
import { SIMPEG_DATA_KELUARGA_LIST } from "../constants";

const KeluargaService = {
  getList(data) {
    return BaseInstance.post(SIMPEG_DATA_KELUARGA_LIST, data);
  }
};

export default KeluargaService;
